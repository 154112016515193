<!--
 * @Description:用户端-首页-停车缴费-停车代缴-订单详情  parForParkCount
 * @Author: zhoucheng
 * @Github:无
 * @Date: 2021-04-01 11:55:42
 * @LastEditors: zhoucheng
-->
<template>
  <div class='body'>
    <van-nav-bar title="订单详情"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <van-row class="main">
      <van-row class="infoBox">
        <van-row class="infoTitle">
          订单详情
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">车牌号</van-col>
          <van-col class="details">{{orderList.plateNumber}}</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">在停地</van-col>
          <van-col class="details">{{orderList.parkName}}</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">泊位号</van-col>
          <van-col class="details">{{orderList.parkSpaceNumber}}</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">停车时长</van-col>
          <van-col class="details">{{orderList.parkDuration}}</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">开始时间</van-col>
          <van-col class="details">{{orderList.entranceTime}}</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">计费规则</van-col>
          <van-col class="details"
                   style="color:blue"
                   @click="queryBillingRuleDetail">查看计费规则详情</van-col>
        </van-row>
        <van-row class="infoLine"
                 style="margin-bottom:8px">
          <van-col class="name">应收费用</van-col>
          <van-col class="pay"
                   style="width:200px">
            <span style="color:#19A9FC;font-size:20px;">{{orderList.receivableMoneyAmount}}
            </span>
            <span v-if="showFree===2"> - </span>
            <span v-if="showFree===2"
                  style="color:red;font-size:20px;"> {{orderList.receivableMoneyAmount}} </span>
            元
          </van-col>
        </van-row>
      </van-row>
      <van-row class="infoBox">
        <van-row class="infoLine"
                 style="margin-bottom:8px">
          <van-col :span=20
                   class="name">订单有疑虑？</van-col>
          <van-col :span=4
                   class="pay"
                   style="float:right;color:#0382E9"
                   @click="toOrderComplain">
            点击申诉
          </van-col>
        </van-row>
      </van-row>
      <!-- 优惠选择 -->
      <van-row class="discount">
        <van-col :span=17
                 class="discount-left">优惠选择</van-col>
        <van-col :span=7
                 class="discount-right">
          暂无可用
          <img src="@/assets/homePage/back.png"
               alt="">
        </van-col>
      </van-row>
      <!-- 底部支付 -->
      <van-row class="bottom">
        <van-col :span="5"
                 class="bottom-col">
          金额:
        </van-col>
        <van-col :span=5
                 class="bottom-totalMoney">
          <span v-if="showFree===1">{{totalNeedMoney}}</span>
          <span v-if="showFree===2">0.00</span>
        </van-col>
        <van-col :span=7>元</van-col>
        <van-col :span="7"
                 type="info"
                 class="bottom-next"
                 @click="handleClickPay">
          支 付
        </van-col>
      </van-row>
    </van-row>
    <van-popup v-model="payPop"
               :style="{ width:'80%',height: '25%' }"
               closeable>
      <van-row class="payPopTitle">尊敬的车主</van-row>
      <van-row class="payPopcontent">您停靠的车行道车位，请确定支付后，地锁挡板完全降下后再驶离。</van-row>
      <van-button class="payPopButton"
                  @click="popupClickConfirm">确 认</van-button>
    </van-popup>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import duration from '@/utils/duration'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      showFree: 1, // 计费规则是否免费 2是免费 1是收费
      payPop: false, // 支付弹窗
      orderList: {}, // 订单详情列表
      plateNumber: '', // 路由车牌号
      container: null, // 订单申诉
      totalNeedMoney: 0, // 当前订单
      lackOrderShow: 1, // 显示欠费订单列表
      lackOrderList: [] // 获取欠费订单列表
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.getOrderList()
    this.getLackOrder()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () {

  }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 获取订单详情列表
    getOrderList () {
      const info = {
        plateNumber: this.$route.query.plateNumber,
        numberPlateColorCode: this.$route.query.numberPlateColorCode
      }
      this.$payForParkCount.queryOnlineOrderBySinglePlateNumber(info).then(res => {
        this.orderList = res.resultEntity
        this.orderList.receivableMoneyAmount = (this.orderList.receivableMoneyAmount / 100).toFixed(2)
        this.orderList.parkDuration = duration(this.orderList.parkDuration)
        this.moneyAmount = res.resultEntity.receivableMoneyAmount
        this.moneyPay = res.resultEntity.receivedMoneyAmount
        this.orderSequence = res.resultEntity.orderSequence
        this.billingRuleDefId = res.resultEntity.billingRuleDefId
        // 当前金额
        this.totalNeedMoney = this.orderList.receivableMoneyAmount
        // 查询是否收费
        this.queryForFree()
      })
    },
    // 查询该计费规则是否收费
    queryForFree () {
      const info = {
        billingRuleDefId: this.billingRuleDefId
      }
      this.$billRuleDetail.queryBillingRuleById(info).then(res => {
        // 2是免费 1是收费
        if (res.resultEntity.chargeStatusCode === 2) {
          this.showFree = 2
        } else if (res.resultEntity.chargeStatusCode === 1) {
          this.showFree = 1
        }
      })
    },
    // 支付按钮
    handleClickPay () {
      this.payPop = true
    },
    // 弹窗点击确认
    popupClickConfirm () {
      // 不收费直接返回，订单结束
      if (this.showFree === 2) {
        const info = {
          orderSequence: this.orderSequence,
          billingRuleDefId: this.billingRuleDefId
        }
        this.$billRuleDetail.completeOrderFree(info).then(() => {
          this.$toast('本单免费')
          this.$router.go(-2)
        })
      } else if (this.showFree === 1) {
        if (this.moneyAmount && this.orderSequence) {
          this.$router.push({ name: 'choosePayStyle', query: { payMoney: this.moneyAmount, payOrder: this.orderSequence } })
        }
      }
    },
    // 支付按钮
    // handleClickPay () {
    //   if (this.moneyAmount && this.orderSequence) {
    //     this.$router.push({ name: 'choosePayStyle', query: { payMoney: this.moneyAmount, payOrder: this.orderSequence } })
    //   }
    // },
    // 获取欠费订单
    getLackOrder () {
      const info = {
        plateNumber: this.$route.query.plateNumber
      }
      this.$lackOrder.queryArrangeOrder(info).then(res => {
        if (res.resultEntity.length !== 0) {
          this.$router.push({ name: 'lackOrder', query: { plateNumber: this.$route.query.plateNumber } })
        }
      })
    },

    // 跳转订单申诉
    toOrderComplain () {
      this.$router.push({ name: 'orderComplain', query: { orderSequence: this.orderSequence, moneyAmount: this.moneyAmount, moneyPay: this.moneyPay } })
    },
    // 查看计费规则详情
    queryBillingRuleDetail () {
      this.$router.push({ name: 'billRuleDetail', query: { billingRuleDefId: this.billingRuleDefId } })
    },
    // 顶部返回按钮
    handleClickTopBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.body {
  width: 100%;
  overflow-x: hidden;
  .main {
    height: 100px;
    .infoBox {
      margin: 12px 3.2%;
      background-color: #ffffff;
      width: 93.6%;
      border-radius: 5px;
      padding-top: 12px;
      .infoTitle {
        margin: 7.9px 0 12px 12px;
        font-size: 15px;
        color: #333;
        font-weight: 500;
        font-family: PingFang-SC;
      }
      .line {
        border: 1px dashed #cfcfcf;
        width: 92.8%;
        margin-left: 12px;
      }
      .infoLine {
        height: 33.5px;
        line-height: 33.5px;
        .name {
          display: block;
          width: 103.5px;
          line-height: 33.5px;
          font-size: 15px;
          color: #909090;
          font-weight: 400;
          margin-left: 12px;
        }
        .details {
          display: block;
          width: 100%px;
          line-height: 33.5px;
          font-size: 15px;
          color: #333333;
        }
        .pay {
          display: block;
          width: 80px;
          line-height: 33.5px;
          font-size: 15px;
          color: #333333;
        }
      }
    }
    .infoBoxMid {
      width: 93.6%;
      height: 40px;
      line-height: 40px;
      margin: 12px 3.2%;
      background-color: #ffffff;
      border-radius: 5px;
      .infoLine {
        height: 40px;
        line-height: 40px;
        .name {
          display: block;
          width: 103.5px;
          line-height: 40px;
          font-size: 15px;
          color: #909090;
          font-weight: 400;
          margin-left: 12px;
        }
        .pay {
          display: block;
          width: 80px;
          line-height: 40px;
          font-size: 15px;
          color: #333333;
        }
      }
    }
  }
}
.lackOrderDiv {
  width: 93.6%;
  margin-left: 3.2%;
  .lackOrderDiv-title {
    width: 100%;
    height: 26px;
    line-height: 26px;
    font-size: 17px;
    color: #333333;
    margin-bottom: 10px;
  }
  .lackOrderDiv-content {
    width: 100%;
    height: 140px;
    background: #ffffff;
    margin-bottom: 20px;
    .lackOrderDiv-content-lineOne {
      width: 100%;
      height: 40px;
      line-height: 40px;
      padding-left: 10px;
    }
    .lackOrderDiv-content-lineTwo {
      width: 100%;
      height: 1px;
      background: #e7e8e8;
    }
    .lackOrderDiv-content-lineThree {
      padding-left: 10px;
      padding-bottom: 120px;
      .lackOrderDiv-content-lineThree-left {
        .lackOrderDiv-content-lineThree-left-lineOne {
          height: 22px;
          line-height: 22px;
          color: #666666;
          font-size: 12px;
        }
        .lackOrderDiv-content-lineThree-left-lineTwo {
          height: 24px;
          line-height: 24px;
          color: #333333;
          font-size: 15px;
        }
        .lackOrderDiv-content-lineThree-left-lineThree {
          height: 22px;
          line-height: 22px;
          color: #666666;
          font-size: 12px;
        }
        .lackOrderDiv-content-lineThree-left-lineFour {
          height: 24px;
          line-height: 24px;
          color: #333333;
          font-size: 15px;
        }
      }
      .lackOrderDiv-content-lineThree-right {
        .lackOrderDiv-content-lineThree-right-lineOne {
          height: 22px;
          line-height: 22px;
          color: #666666;
          font-size: 12px;
        }
        .lackOrderDiv-content-lineThree-right-lineTwo {
          height: 24px;
          line-height: 24px;
          color: #f56060;
          font-size: 15px;
        }
        .lackOrderDiv-content-lineThree-right-lineThree {
          height: 22px;
          line-height: 22px;
          color: #666666;
          font-size: 12px;
        }
        .lackOrderDiv-content-lineThree-right-lineFour {
          height: 24px;
          line-height: 24px;
          color: #333333;
          font-size: 15px;
        }
      }
    }
  }
}
.discount {
  width: 100%;
  height: 46px;
  line-height: 46px;
  background: #ffffff;
  position: fixed;
  bottom: 46px;
  padding-left: 3.2%;
  margin-top: 10px;
  .discount-left {
    padding-left: 3.2%;
  }
  .discount-right {
    img {
      margin-left: 5px;
      margin-top: 3px;
    }
  }
}
.bottom {
  width: 100%;
  height: 46px;
  line-height: 46px;
  background-color: white;
  position: fixed;
  bottom: 0;
  .bottom-col {
    padding-left: 20px;
    .col-checkBox {
      margin: 12px 0 0 12px;
      font-size: 15px;
      color: #333333;
    }
  }
  .bottom-totalMoney {
    font-size: 19px;
    color: #19a9fc;
  }
  .bottom-totalMoneyYuan {
    font-size: 15px;
    color: #333333;
  }
  .bottom-next {
    font-size: 17px;
    color: #ffffff;
    background: #19a9fc;
    text-align: center;
  }
}
.container {
  height: 100px;
  width: 40px;
  position: absolute;
  z-index: 1;
  left: 86%;
  top: 82px;
}
.payPopTitle {
  width: 80%;
  height: 30px;
  line-height: 30px;
  margin-top: 20px;
  padding-left: 10%;
  font-family: PingFangSC-Medium;
  font-size: 18px;
  color: #374763;
  letter-spacing: 0;
}
.payPopcontent {
  width: 80%;
  height: 60px;
  line-height: 20px;
  padding-left: 10%;
  font-family: PingFangSC-Regular;
  font-size: 15px;
  color: #5f6f8c;
  letter-spacing: 0;
}
.payPopButton {
  width: 32%;
  height: 34px;
  margin-left: 34%;
  background: #1aa8fb;
  border-radius: 4px;
  border-radius: 4px;
  font-family: PingFangSC-Regular;
  font-size: 15px;
  color: #ffffff;
  letter-spacing: 0;
}
</style>
